.layout-div {
    height: 50vh;
    max-width: 95%;
    font-size: 3.2vmin;
    /* width: 1200px; */
    margin-left: 2vw;
    margin-right: 2vw;
    font-family: 'Libre Baskerville', serif;
    font-weight: 900;
    background-image: url(../images/blue-felt.jpg);
    background-size: contain;
    background-position: center;
    border-radius: 20px;
    padding: 2vh 1.115vw;
    /* padding-left: .5vw; */
    /* padding-right: 1vw; */
    /* padding-right: 20px; */
    cursor: default;
    color: rgb(235, 235, 235);
    /* width: 100%; */
    /* margin-bottom: 10vh; */
}

/* come back to here */
.zeroes {
    display: grid;
    grid-template-columns: 4.2vw;
    grid-template-rows: 13.74vh 0.51vh 13.74vh;
    border-left: .8vw rgb(22, 22, 22) solid;
    /* border-top: 3px rgb(22, 22, 22) solid; */
    box-shadow: inset 0vh -1.15vh rgb(22, 22, 22);
    border-radius: 0px 0px 0px 10px;
}

.inside-and-columns {
    display: grid;
    grid-template-columns: 0.51vw 4.2vw 0.51vw 4.2vw 0.51vw 4.2vw 0.51vw 4.2vw 0.51vw 4.2vw 0.51vw 4.2vw 0.51vw 4.2vw 0.51vw 4.2vw 0.51vw 4.2vw 0.51vw 4.2vw 0.51vw 4.2vw 0.51vw 4.2vw 4.2vw;
    grid-template-rows: 8.8vh 0.51vh 8.8vh 0.51vh 8.8vh 0.51vh ;
    /* border: 2px rgb(22, 22, 22) solid; */
}

.dozens {
    display: grid;
    grid-template-columns: 6.77vw 27vw 27.05vw 27.7vw;
    grid-template-rows: 9.5vh;
    color: rgb(22, 22, 22);
}

.even-money-bets {
    font-size: 4.5vmin;
    display: grid;
    grid-template-columns: 6.76vw 13.5vw 13.52vw 13.52vw 13.52vw 13.86vw 13.86vw;
    grid-template-rows: 9vh;
    color: rgb(22, 22, 22);
}

#one-chip-cursor {
    cursor: url(../images/1-chip-cursor.png), auto;
}

#five-chip-cursor {
    cursor: url(../images/5-chip-cursor.png), auto;
}

#twenty-five-chip-cursor {
    cursor: url(../images/25-chip-cursor.png), auto;
}

#one-hundred-chip-cursor {
    cursor: url(../images/100-chip-cursor.png), auto;
}

#five-hundred-chip-cursor {
    cursor: url(../images/500-chip-cursor.png), auto;
}

#one-thousand-chip-cursor {
    cursor: url(../images/1000-chip-cursor.png), auto;
}

#ten-thousand-chip-cursor {
    cursor: url(../images/10k-chip-cursor.png), auto;
}

#fifty-thousand-chip-cursor {
    cursor: url(../images/50k-chip-cursor.png), auto;
}

#hundred-thousand-chip-cursor {
    cursor: url(../images/100k-chip-cursor.png), auto;
}

#five-hundred-thousand-chip-cursor {
    cursor: url(../images/500k-chip-cursor.png), auto;
}

#one-million-chip-cursor {
    cursor: url(../images/1m-chip-cursor.png), auto;
}

.bet-box {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    /* border: 1px rgb(22, 22, 22) solid; */
    /* cursor: url(../images/1-chip-cursor.png), auto; */
}

.bet-box > .green {
    background-color: rgb(90, 161, 34);
    margin: 2px;
}

.bet-box > .red {
    background-color: rgb(254,0,0);
    margin: 2px;
}

.bet-box > .black {
    background-color: rgb(22, 22, 22);
    margin: 2px;
}

.bet-box:hover {
    background-color: gold;
    /* background: linear-gradient(0.25turn, rgba(255, 217, 0, 0.85), gold, rgba(255, 217, 0, 0.85)); */

}

.zeroes-inside-columns {
    display: flex;
    width: 100%;
    border-top: .65vw rgb(22, 22, 22) solid;
    /* border-right: .7vw rgb(22, 22, 22) solid; */
    /* border-bottom: .7vw rgb(22, 22, 22) solid; */
    /* flex-direction: row; */
    /* justify-content: center; */
    border-radius: 10px 10px 10px 10px;
    /* box-shadow: inset 0vh 1vh rgb(22, 22, 22); */
}

.empty-column {
    border-radius: 0px 0px 10px 0px;
}

#straight-up-number {
    font-size: 5vmin;
    /* padding: 10px 10px; */
}

.number {
    /* border: 1px rgb(22, 22, 22) solid; */
    border-radius: 60pt;
    width: 100%;
    height: 75%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

#empty-remove-border {
    /* border-left: 1.7vw transparent solid; */
}

.fill {
    background-color: rgb(22, 22, 22);
}

.diamond {
    height: 7.8vmin;
}

.ribbon-div {
    height: 10vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    
}

.dozen {
    border-bottom: .75vw rgb(22, 22, 22) solid;
    border-left: .75vw rgb(22, 22, 22) solid;
}

.right-dozen {
    border-right: .75vw rgb(22, 22, 22) solid;
    border-bottom: .75vw rgb(22, 22, 22) solid;
    border-left: .75vw rgb(22, 22, 22) solid;
}

.ribbon-img {
    position: absolute;
    width: 32vmin;
    margin-top: 0.5vh;
}

.dozen-text {
    position: absolute;
    /* padding-bottom: 44px; */
    padding-bottom: 5vh;
    /* margin: 0; */
}

.column {
    color: rgb(22, 22, 22);
    border-left: .75vw rgb(22, 22, 22) solid;
    border-right: .75vw rgb(22, 22, 22) solid;
    font-size: 2.4vmin;
}

.split, .basket, .corner, .street, .double-street {
    background-color: rgb(22, 22, 22);
}

.even-money {
    border-bottom: .75vw rgb(22, 22, 22) solid;
    border-left: .75vw rgb(22, 22, 22) solid;
}

.left-even-money {
    border-bottom: .75vw rgb(22, 22, 22) solid;
    border-left: .75vw rgb(22, 22, 22) solid;
    border-radius: 0px 0px 0px 10px;

}

.right-even-money {
    border-right: .75vw rgb(22, 22, 22) solid;
    border-bottom: .75vw rgb(22, 22, 22) solid;
    border-left: .75vw rgb(22, 22, 22) solid;
    border-radius: 0px 0px 10px 0px;
}

.blank-chip {
    height: 7vh;
    z-index: 50;
    /* position: absolute; */
}   

.chip-and-bet {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
}

.bet-text {
    position: absolute;
    font-size: 1vmin;
    color: rgb(22, 22, 22);
    z-index: 100;
    padding-bottom: 1.8vmin;
    text-shadow: 0px 0px 1px white;
}

.dozen-chip {
    
}

.winning-num {
    animation-name: flashingNumber;
    animation-duration: 1s;
    animation-iteration-count: infinite;
}

@keyframes flashingNumber {
    0% {background-color: gold;}
    50% {background-color: rgba(255, 217, 0, 0);}
    100% {background-color: gold;}
}
