.progress-bar-vertical {
    width: 20px;
    min-height: 100px;
    margin-right: 5px;
    float: left;
    display: -webkit-box;  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
    display: -ms-flexbox;  /* TWEENER - IE 10 */
    display: -webkit-flex; /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
    display: flex;         /* NEW, Spec - Firefox, Chrome, Opera */
    align-items: flex-end;
    -webkit-align-items: flex-end; /* Safari 7.0+ */
  }
  
  .progress-bar-vertical .progress-bar {
    width: 100%;
    height: 0;
    -webkit-transition: height 0.6s ease;
    -o-transition: height 0.6s ease;
    transition: height 0.6s ease;
  }
.progress-group {
    display:-ms-flexbox;
    display:flex;
    -ms-flex-flow:row wrap;
    flex-flow:row wrap;
    margin-bottom:1rem
   }
   .progress-group-prepend {
    -ms-flex:0 0 100px;
    flex:0 0 100px;
    -ms-flex-item-align:center;
    -ms-grid-row-align:center;
    align-self:center
   }
   .progress-group-icon {
    font-size:1.09375rem
   }
   html:not([dir=rtl]) .progress-group-icon {
    margin:0 .25rem 0 .25rem
   }
   *[dir=rtl] .progress-group-icon {
    margin:0 .25rem 0 1rem
   }
   .progress-group-text {
    font-size:.765625rem;
    color:#768192
   }
   .progress-group-header {
    display:-ms-flexbox;
    display:flex;
    -ms-flex-preferred-size:100%;
    flex-basis:100%;
    -ms-flex-align:end;
    align-items:flex-end;
    margin-bottom:.25rem;
   }
   .progress-group-bars {
    -ms-flex-positive:1;
    flex-grow:1;
    -ms-flex-item-align:center;
    -ms-grid-row-align:center;
    align-self:center
   }
   .progress-group-bars .progress:not(:last-child) {
    margin-bottom:2px
   }
   .progress-group-header+.progress-group-bars {
    -ms-flex-preferred-size:100%;
    flex-basis:100%
   }
   .progress-xs {
    height:4px
   }
/* Miligram overrides */
a {
    font-family: "Raleway", sans-serif;
    color: #404041;
}

/* Utility */
.table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}

/* Main */

.content {
    padding: 2rem;
    background: #ffffff;
    border-radius: 0.4rem;
    /* Thanks Stripe */
    box-shadow: 0 7px 14px 0 rgba(60, 66, 87, 0.1),
        0 3px 6px 0 rgba(0, 0, 0, 0.07);
}
.actions a {
    font-weight: bold;
    padding: 0 0.4rem;
}
th {
    white-space: nowrap;
}

/* Nav bar */
.top-nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 112rem;
    padding: 2rem;
    margin: 0 auto 2rem;
}
.top-nav-title a {
    font-size: 2.4rem;
    color: #d33c43;
}
.top-nav-title span {
    color: #404041;
}
.top-nav-links a {
    margin: 0 0.5rem;
}
.top-nav-title a,
.top-nav-links a {
    font-weight: bold;
}

.side-nav-item {
    display: block;
    padding: 0.5rem 0;
}

/* View action */
.view.content .text {
    margin-top: 1.2rem;
}
.related {
    margin-top: 2rem;
}

/* Flash messages */
.message {
    padding: 1rem;

    background: #eff8ff;
    color: #2779bd;

    border-color: #6cb2eb;
    border-width: 1px;
    border-style: solid;
    border-radius: 4px;
    margin-bottom: 2rem;
}
.message.hidden {
    display: none;
}
.message.success {
    background: #e3fcec;
    color: #1f9d55;
    border-color: #51d88a;
}
.message.error {
    background: #fcebea;
    color: #cc1f1a;
    border-color: #ef5753;
}

/* Forms */
.input.radio,
.input.checkbox {
    margin-bottom: 2.0rem;
}
.input.radio input,
.input.checkbox input {
    margin: 0;
}
.input.radio label,
.input.checkbox label {
    margin: 0;
    display: flex;
    align-items: center;
}
.input.radio label > input,
.input.checkbox label > input {
    margin-right: 1.0rem;
}
.input.radio label:first-of-type {
    margin-bottom: 2.0rem;
}

/* Paginator */
.paginator {
    text-align: right;
}
.pagination {
    display: flex;
    justify-content: center;
    list-style: none;
    padding: 0;
    margin: 0 0 1rem;
}
.pagination li {
    margin: 0 0.5rem;
}
.prev.disabled a,
.next.disabled a {
    cursor: not-allowed;
    color: #606c76;
}
.asc:after {
    content: " \2193";
}
.desc:after {
    content: " \2191";
}

/* Error */
.error-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: center;
}

@media screen and (max-width: 640px) {
    .top-nav {
        margin: 0 auto;
    }
    .side-nav {
        margin-bottom: 1rem;
    }
    .heading {
        margin-bottom: 1rem;
    }
    .side-nav-item {
        display: inline;
        margin: 0 1.5rem 0 0;
    }
    .asc:after {
        content: " \2192";
    }
    .desc:after {
        content: " \2190";
    }
}
.r{color: #FF0000;}
.rf{color: #FFAAAA;}
.r2{color: #CC0000;}
.bl{color: #000000;}
.g{color: #AAAAAA;}
.gn{color: #00CC00;}
.gf{color: #AACCAA;}
.b{color: #0000CC;}
.bf{color: #AAAACC;}
.o{color: #CC7F05;}
.of{color: #F69906;}