.neon {
    font-family: 'Londrina Outline', cursive;
    text-transform: uppercase;
    color: #fff;
    letter-spacing: 0.15em;
}
.pink {
    text-shadow:
        0 0 5px #ff7bac,
        0 0 10px #ff7bac,
        0 0 20px #ff7bac,
        0 0 40px #ff7bac,
        0 0 80px #ff7bac,
        0 0 90px #ff7bac,
        0 0 100px #ff7bac,
        0 0 150px #ff7bac;
}
.blue {
    text-shadow:
        0 0 5px #7bacff,
        0 0 10px #7bacff,
        0 0 20px #7bacff,
        0 0 40px #7bacff,
        0 0 80px #7bacff,
        0 0 90px #7bacff,
        0 0 100px #7bacff,
        0 0 150px #7bacff;
}
.green {
    text-shadow:
        0 0 5px #7bffce,
        0 0 10px #7bffce,
        0 0 20px #7bffce,
        0 0 40px #7bffce,
        0 0 80px #7bffce,
        0 0 90px #7bffce,
        0 0 100px #7bffce,
        0 0 150px #7bffce;
}
