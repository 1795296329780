.sidebar {
    border: 1px solid black;
}
.sidebarSearch {
    display: flex;
    align-items: center;
    background-color: khaki;
    height: 40px;
}

.sidebarSearchContainer {
    display: flex;
    align-items: center;
    background-color: white;
    width: 100%;
    height: 35px;
}

.sidebarSearchContainer > input {
    border: none;
    margin-left: 10px
}

.sidebarChats {
    flex: 1;
    background-color: white;
}