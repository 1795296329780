.glassLogin {
    color: white;
    background-color: rgba(150, 125, 125, 0.5);
    border-radius: 20px;
}

.update {
    width: 100%;
    padding: 10px;
    border-radius: 20px;
    margin-bottom: 20px;
}