body{
    margin: 0;
    padding: 0;
	background-repeat:no-repeat;
	background-size:cover;
	background-position:absolute;
    font-family: sans-serif;
}
.login-left-box {
    margin: 0;
    padding: 0;
    background-image: url(../images/login2.jpg);
	background-repeat:no-repeat;
	background-size:cover;
	background-position:center;
    font-family: sans-serif;
}
.register {
    height: 100vh;
    margin: 0;
    padding: 0;
    background-image: url(../images/register.jpg);
	background-repeat:no-repeat;
	background-size:cover;
	background-position:center;
    font-family: sans-serif;
}
.login-box{
    width: 400px;
    height: 400px;
    background: rgba(150, 125, 125, 0.5);
    color: #fff;
    box-sizing: border-box;
	border-radius: 20px;
    padding: 30px 30px;
}
.avatar{
    width: 100px;
    height: 100px;
    border-radius: 50%;
    position: absolute;
    top: -50px;
    left: calc(50% - 50px);
}
h1{
    margin: 0;
    padding: 0 0 20px;
    text-align: center;
    font-size: 22px;
}
.login-box p{
    margin: 0;
    padding: 0;
    font-weight: bold;
}
.login-box input{
    width: 100%;
    margin-bottom: 20px;
}
.login-box input[type="text"], input[type="password"], input[type="email"]
{
    border: none;
    border-bottom: 1px solid #fff;
    background: transparent;
    outline: none;
    height: 40px;
    color: #fff;
    font-size: 16px;
}
.login-box input[type="submit"]
{
    border: none;
    outline: none;
    height: 40px;
    background: #1c8adb;
    color: #fff;
    font-size: 18px;
    border-radius: 20px;
}
.login-box input[type="submit"]:hover
{
    cursor: pointer;
    background: #39dc79;
    color: #000;
}

.login-box a{
    text-decoration: none;
    font-size: 14px;
    color: #fff;
}
.login-box a:hover
{
    color: #39dc79;
}
@media (min-width: 950px) {
    /* breite Browserfenster */
    .img
      {
         width: 400px; 
      }
  }
  @media (min-width: 450px) and 
   (max-width: 950px) {
    /* Darstellung auf Netbooks */ 
     .img
     {
         width: 400px;
     }
    } 
  @media (max-width: 450px)
  {
      /* mobile Geräte */
      .img
      {
         width: 100%; 
      }
      .login-left-box {
          background-position: inherit;
      }
  }