.progress-bar-vertical {
    width: 20px;
    min-height: 100px;
    margin-right: 5px;
    float: left;  /* OLD - iOS 6-, Safari 3.1-6, BB7 */  /* TWEENER - IE 10 */ /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
    display: flex;         /* NEW, Spec - Firefox, Chrome, Opera */
    align-items: flex-end;
    -webkit-align-items: flex-end; /* Safari 7.0+ */
  }
  
  .progress-bar-vertical .progress-bar {
    width: 100%;
    height: 0;
    transition: height 0.6s ease;
  }
.progress-group {
    display:flex;
    flex-flow:row wrap;
    margin-bottom:1rem
   }
   .progress-group-prepend {
    flex:0 0 100px;
    -ms-grid-row-align:center;
    align-self:center
   }
   .progress-group-icon {
    font-size:1.09375rem
   }
   html:not([dir=rtl]) .progress-group-icon {
    margin:0 .25rem 0 .25rem
   }
   *[dir=rtl] .progress-group-icon {
    margin:0 .25rem 0 1rem
   }
   .progress-group-text {
    font-size:.765625rem;
    color:#768192
   }
   .progress-group-header {
    display:flex;
    flex-basis:100%;
    align-items:flex-end;
    margin-bottom:.25rem;
   }
   .progress-group-bars {
    flex-grow:1;
    -ms-grid-row-align:center;
    align-self:center
   }
   .progress-group-bars .progress:not(:last-child) {
    margin-bottom:2px
   }
   .progress-group-header+.progress-group-bars {
    flex-basis:100%
   }
   .progress-xs {
    height:4px
   }
/* Miligram overrides */
a {
    font-family: "Raleway", sans-serif;
    color: #404041;
}

/* Utility */
.table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}

/* Main */

.content {
    padding: 2rem;
    background: #ffffff;
    border-radius: 0.4rem;
    /* Thanks Stripe */
    box-shadow: 0 7px 14px 0 rgba(60, 66, 87, 0.1),
        0 3px 6px 0 rgba(0, 0, 0, 0.07);
}
.actions a {
    font-weight: bold;
    padding: 0 0.4rem;
}
th {
    white-space: nowrap;
}

/* Nav bar */
.top-nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 112rem;
    padding: 2rem;
    margin: 0 auto 2rem;
}
.top-nav-title a {
    font-size: 2.4rem;
    color: #d33c43;
}
.top-nav-title span {
    color: #404041;
}
.top-nav-links a {
    margin: 0 0.5rem;
}
.top-nav-title a,
.top-nav-links a {
    font-weight: bold;
}

.side-nav-item {
    display: block;
    padding: 0.5rem 0;
}

/* View action */
.view.content .text {
    margin-top: 1.2rem;
}
.related {
    margin-top: 2rem;
}

/* Flash messages */
.message {
    padding: 1rem;

    background: #eff8ff;
    color: #2779bd;

    border-color: #6cb2eb;
    border-width: 1px;
    border-style: solid;
    border-radius: 4px;
    margin-bottom: 2rem;
}
.message.hidden {
    display: none;
}
.message.success {
    background: #e3fcec;
    color: #1f9d55;
    border-color: #51d88a;
}
.message.error {
    background: #fcebea;
    color: #cc1f1a;
    border-color: #ef5753;
}

/* Forms */
.input.radio,
.input.checkbox {
    margin-bottom: 2.0rem;
}
.input.radio input,
.input.checkbox input {
    margin: 0;
}
.input.radio label,
.input.checkbox label {
    margin: 0;
    display: flex;
    align-items: center;
}
.input.radio label > input,
.input.checkbox label > input {
    margin-right: 1.0rem;
}
.input.radio label:first-of-type {
    margin-bottom: 2.0rem;
}

/* Paginator */
.paginator {
    text-align: right;
}
.pagination {
    display: flex;
    justify-content: center;
    list-style: none;
    padding: 0;
    margin: 0 0 1rem;
}
.pagination li {
    margin: 0 0.5rem;
}
.prev.disabled a,
.next.disabled a {
    cursor: not-allowed;
    color: #606c76;
}
.asc:after {
    content: " \2193";
}
.desc:after {
    content: " \2191";
}

/* Error */
.error-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: center;
}

@media screen and (max-width: 640px) {
    .top-nav {
        margin: 0 auto;
    }
    .side-nav {
        margin-bottom: 1rem;
    }
    .heading {
        margin-bottom: 1rem;
    }
    .side-nav-item {
        display: inline;
        margin: 0 1.5rem 0 0;
    }
    .asc:after {
        content: " \2192";
    }
    .desc:after {
        content: " \2190";
    }
}
.r{color: #FF0000;}
.rf{color: #FFAAAA;}
.r2{color: #CC0000;}
.bl{color: #000000;}
.g{color: #AAAAAA;}
.gn{color: #00CC00;}
.gf{color: #AACCAA;}
.b{color: #0000CC;}
.bf{color: #AAAACC;}
.o{color: #CC7F05;}
.of{color: #F69906;}
.neon {
    font-family: 'Londrina Outline', cursive;
    text-transform: uppercase;
    color: #fff;
    letter-spacing: 0.15em;
}
.pink {
    text-shadow:
        0 0 5px #ff7bac,
        0 0 10px #ff7bac,
        0 0 20px #ff7bac,
        0 0 40px #ff7bac,
        0 0 80px #ff7bac,
        0 0 90px #ff7bac,
        0 0 100px #ff7bac,
        0 0 150px #ff7bac;
}
.blue {
    text-shadow:
        0 0 5px #7bacff,
        0 0 10px #7bacff,
        0 0 20px #7bacff,
        0 0 40px #7bacff,
        0 0 80px #7bacff,
        0 0 90px #7bacff,
        0 0 100px #7bacff,
        0 0 150px #7bacff;
}
.green {
    text-shadow:
        0 0 5px #7bffce,
        0 0 10px #7bffce,
        0 0 20px #7bffce,
        0 0 40px #7bffce,
        0 0 80px #7bffce,
        0 0 90px #7bffce,
        0 0 100px #7bffce,
        0 0 150px #7bffce;
}

body{
    margin: 0;
    padding: 0;
	background-repeat:no-repeat;
	background-size:cover;
	background-position:absolute;
    font-family: sans-serif;
}
.login-left-box {
    margin: 0;
    padding: 0;
    background-image: url(/static/media/login2.30b0075c.jpg);
	background-repeat:no-repeat;
	background-size:cover;
	background-position:center;
    font-family: sans-serif;
}
.register {
    height: 100vh;
    margin: 0;
    padding: 0;
    background-image: url(/static/media/register.4449f1f7.jpg);
	background-repeat:no-repeat;
	background-size:cover;
	background-position:center;
    font-family: sans-serif;
}
.login-box{
    width: 400px;
    height: 400px;
    background: rgba(150, 125, 125, 0.5);
    color: #fff;
    box-sizing: border-box;
	border-radius: 20px;
    padding: 30px 30px;
}
.avatar{
    width: 100px;
    height: 100px;
    border-radius: 50%;
    position: absolute;
    top: -50px;
    left: calc(50% - 50px);
}
h1{
    margin: 0;
    padding: 0 0 20px;
    text-align: center;
    font-size: 22px;
}
.login-box p{
    margin: 0;
    padding: 0;
    font-weight: bold;
}
.login-box input{
    width: 100%;
    margin-bottom: 20px;
}
.login-box input[type="text"], input[type="password"], input[type="email"]
{
    border: none;
    border-bottom: 1px solid #fff;
    background: transparent;
    outline: none;
    height: 40px;
    color: #fff;
    font-size: 16px;
}
.login-box input[type="submit"]
{
    border: none;
    outline: none;
    height: 40px;
    background: #1c8adb;
    color: #fff;
    font-size: 18px;
    border-radius: 20px;
}
.login-box input[type="submit"]:hover
{
    cursor: pointer;
    background: #39dc79;
    color: #000;
}

.login-box a{
    text-decoration: none;
    font-size: 14px;
    color: #fff;
}
.login-box a:hover
{
    color: #39dc79;
}
@media (min-width: 950px) {
    /* breite Browserfenster */
    .img
      {
         width: 400px; 
      }
  }
  @media (min-width: 450px) and 
   (max-width: 950px) {
    /* Darstellung auf Netbooks */ 
     .img
     {
         width: 400px;
     }
    } 
  @media (max-width: 450px)
  {
      /* mobile Geräte */
      .img
      {
         width: 100%; 
      }
      .login-left-box {
          background-position: inherit;
      }
  }
html {
  --scrollbarBG: #cfd8dc;
  --thumbBG: #90a4ae;
}
div::-webkit-scrollbar {
  width: 11px;
}
div {
  scrollbar-width: thin;
  scrollbar-color: #90a4ae #cfd8dc;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}
div::-webkit-scrollbar-track {
  background: #cfd8dc;
  background: var(--scrollbarBG);
}
div::-webkit-scrollbar-thumb {
  background-color: #90a4ae;
  background-color: var(--thumbBG);
  border-radius: 6px;
  border: 3px solid #cfd8dc;
  border: 3px solid var(--scrollbarBG);
}
/*--------------------
Mixins
--------------------*/
/*--------------------
Body
--------------------*/

.bg {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  background: url("https://images.unsplash.com/photo-1451186859696-371d9477be93?crop=entropy&fit=crop&fm=jpg&h=975&ixjsv=2.1.0&ixlib=rb-0.3.5&q=80&w=1925")
    no-repeat 0 0;
  -webkit-filter: blur(80px);
  filter: blur(80px);
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}

/*--------------------
Chat
--------------------*/
.chat {
  height: 500px;

  z-index: 2;
  overflow: hidden;
  box-shadow: 0 5px 30px rgba(0, 0, 0, 0.2);
  background: rgba(0, 0, 0, 0.5);
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

/*--------------------
Chat Title
--------------------*/
.chat-title {
  flex: 0 1 45px;
  position: relative;
  z-index: 2;
  background: rgba(0, 0, 0, 0.2);
  color: #fff;
  text-transform: uppercase;
  text-align: left;
  padding: 10px 10px 10px 50px;
}
.chat-title h1,
.chat-title h2 {
  font-weight: normal;
  font-size: 10px;
  margin: 0;
  padding: 0;
}
.chat-title h2 {
  color: rgba(255, 255, 255, 0.5);
  font-size: 8px;
  letter-spacing: 1px;
}
.chat-title .avatar {
  position: absolute;
  z-index: 1;
  top: 8px;
  left: 9px;
  border-radius: 30px;
  width: 30px;
  height: 30px;
  overflow: hidden;
  margin: 0;
  padding: 0;
  border: 2px solid rgba(255, 255, 255, 0.24);
}
.chat-title .avatar img {
  width: 100%;
  height: auto;
}

/*--------------------
Messages
--------------------*/
.message {
  color: #fff;
}
.messages {
  flex: 1 1 auto;
  color: rgba(255, 255, 255, 0.5);
  overflow-y: scroll;
  position: relative;
  width: 100%;
}
.messages .messages-content {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.messages .message {
  clear: both;
  float: left;
  padding: 5px 5px 5px;
  border-radius: 10px 10px 10px 0;
  background: rgba(0, 0, 0, 0.3);
  margin: 8px 0;
  font-size: 11px;
  line-height: 1.4;
  margin-left: 10px;
  margin-right: 10px;
  position: relative;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
}
.messages .message .timestamp {
  bottom: -15px;
  font-size: 9px;
  color: rgba(255, 255, 255, 0.6);
}
.messages .message::before {
  content: "";
  position: absolute;
  bottom: -6px;
  border-top: 6px solid rgba(0, 0, 0, 0.3);
  left: 0;
  border-right: 7px solid transparent;
}
.messages .message .avatar {
  position: absolute;
  z-index: 1;
  bottom: -15px;
  left: -35px;
  border-radius: 30px;
  width: 30px;
  height: 30px;
  overflow: hidden;
  margin: 0;
  padding: 0;
  border: 2px solid rgba(255, 255, 255, 0.24);
}
.messages .message .avatar img {
  width: 100%;
  height: auto;
}
.messages .message.message-personal {
  float: right;
  color: #fff;
  text-align: right;
  background: linear-gradient(120deg, #248a52, #257287);
  border-radius: 10px 10px 0 10px;
}
.messages .message.message-personal::before {
  left: auto;
  right: 0;
  border-right: none;
  border-left: 5px solid transparent;
  border-top: 4px solid #257287;
  bottom: -4px;
}

.messages .message.loading::before {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  content: "";
  display: block;
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.5);
  z-index: 2;
  margin-top: 4px;
  -webkit-animation: ball 0.45s cubic-bezier(0, 0, 0.15, 1) alternate infinite;
  animation: ball 0.45s cubic-bezier(0, 0, 0.15, 1) alternate infinite;
  border: none;
  -webkit-animation-delay: 0.15s;
  animation-delay: 0.15s;
}
.messages .message.loading span {
  display: block;
  font-size: 0;
  width: 20px;
  height: 10px;
  position: relative;
}
.messages .message.loading span::before {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  content: "";
  display: block;
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.5);
  z-index: 2;
  margin-top: 4px;
  -webkit-animation: ball 0.45s cubic-bezier(0, 0, 0.15, 1) alternate infinite;
  animation: ball 0.45s cubic-bezier(0, 0, 0.15, 1) alternate infinite;
  margin-left: -7px;
}
.messages .message.loading span::after {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  content: "";
  display: block;
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.5);
  z-index: 2;
  margin-top: 4px;
  -webkit-animation: ball 0.45s cubic-bezier(0, 0, 0.15, 1) alternate infinite;
  animation: ball 0.45s cubic-bezier(0, 0, 0.15, 1) alternate infinite;
  margin-left: 7px;
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

/*--------------------
Message Box
--------------------*/
.message-box {
  flex: 0 1 40px;
  width: 100%;
  background: rgba(0, 0, 0, 0.3);
  padding: 10px;
  position: relative;
}
.message-box .message-input {
  background: none;
  border: none;
  outline: none !important;
  resize: none;
  color: rgba(255, 255, 255, 0.7);
  font-size: 11px;
  height: 17px;
  margin: 0;
  padding-right: 20px;
  width: 265px;
}
.message-box textarea:focus:-webkit-placeholder {
  color: transparent;
}
.message-box .message-submit {
  position: absolute;
  z-index: 1;
  top: 9px;
  right: 10px;
  color: #fff;
  border: none;
  background: #248a52;
  font-size: 10px;
  text-transform: uppercase;
  line-height: 1;
  padding: 6px 10px;
  border-radius: 10px;
  outline: none !important;
  transition: background 0.2s ease;
}
.message-box .message-submit:hover {
  background: #1d7745;
}

/*--------------------
Custom Srollbar
--------------------*/
.mCSB_scrollTools {
  margin: 1px -3px 1px 0;
  opacity: 0;
}

.mCSB_inside > .mCSB_container {
  margin-right: 0px;
  padding: 0px;
}

.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: rgba(0, 0, 0, 0.5) !important;
}

/*--------------------
Bounce
--------------------*/
@-webkit-keyframes bounce {
  0% {
    -webkit-transform: matrix3d(0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  4.7% {
    -webkit-transform: matrix3d(
      0.45,
      0,
      0,
      0,
      0,
      0.45,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
    transform: matrix3d(0.45, 0, 0, 0, 0, 0.45, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  9.41% {
    -webkit-transform: matrix3d(
      0.883,
      0,
      0,
      0,
      0,
      0.883,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
    transform: matrix3d(0.883, 0, 0, 0, 0, 0.883, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  14.11% {
    -webkit-transform: matrix3d(
      1.141,
      0,
      0,
      0,
      0,
      1.141,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
    transform: matrix3d(1.141, 0, 0, 0, 0, 1.141, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  18.72% {
    -webkit-transform: matrix3d(
      1.212,
      0,
      0,
      0,
      0,
      1.212,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
    transform: matrix3d(1.212, 0, 0, 0, 0, 1.212, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  24.32% {
    -webkit-transform: matrix3d(
      1.151,
      0,
      0,
      0,
      0,
      1.151,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
    transform: matrix3d(1.151, 0, 0, 0, 0, 1.151, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  29.93% {
    -webkit-transform: matrix3d(
      1.048,
      0,
      0,
      0,
      0,
      1.048,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
    transform: matrix3d(1.048, 0, 0, 0, 0, 1.048, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  35.54% {
    -webkit-transform: matrix3d(
      0.979,
      0,
      0,
      0,
      0,
      0.979,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
    transform: matrix3d(0.979, 0, 0, 0, 0, 0.979, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  41.04% {
    -webkit-transform: matrix3d(
      0.961,
      0,
      0,
      0,
      0,
      0.961,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
    transform: matrix3d(0.961, 0, 0, 0, 0, 0.961, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  52.15% {
    -webkit-transform: matrix3d(
      0.991,
      0,
      0,
      0,
      0,
      0.991,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
    transform: matrix3d(0.991, 0, 0, 0, 0, 0.991, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  63.26% {
    -webkit-transform: matrix3d(
      1.007,
      0,
      0,
      0,
      0,
      1.007,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
    transform: matrix3d(1.007, 0, 0, 0, 0, 1.007, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  85.49% {
    -webkit-transform: matrix3d(
      0.999,
      0,
      0,
      0,
      0,
      0.999,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
    transform: matrix3d(0.999, 0, 0, 0, 0, 0.999, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  100% {
    -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
}
@keyframes bounce {
  0% {
    -webkit-transform: matrix3d(0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  4.7% {
    -webkit-transform: matrix3d(
      0.45,
      0,
      0,
      0,
      0,
      0.45,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
    transform: matrix3d(0.45, 0, 0, 0, 0, 0.45, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  9.41% {
    -webkit-transform: matrix3d(
      0.883,
      0,
      0,
      0,
      0,
      0.883,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
    transform: matrix3d(0.883, 0, 0, 0, 0, 0.883, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  14.11% {
    -webkit-transform: matrix3d(
      1.141,
      0,
      0,
      0,
      0,
      1.141,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
    transform: matrix3d(1.141, 0, 0, 0, 0, 1.141, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  18.72% {
    -webkit-transform: matrix3d(
      1.212,
      0,
      0,
      0,
      0,
      1.212,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
    transform: matrix3d(1.212, 0, 0, 0, 0, 1.212, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  24.32% {
    -webkit-transform: matrix3d(
      1.151,
      0,
      0,
      0,
      0,
      1.151,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
    transform: matrix3d(1.151, 0, 0, 0, 0, 1.151, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  29.93% {
    -webkit-transform: matrix3d(
      1.048,
      0,
      0,
      0,
      0,
      1.048,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
    transform: matrix3d(1.048, 0, 0, 0, 0, 1.048, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  35.54% {
    -webkit-transform: matrix3d(
      0.979,
      0,
      0,
      0,
      0,
      0.979,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
    transform: matrix3d(0.979, 0, 0, 0, 0, 0.979, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  41.04% {
    -webkit-transform: matrix3d(
      0.961,
      0,
      0,
      0,
      0,
      0.961,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
    transform: matrix3d(0.961, 0, 0, 0, 0, 0.961, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  52.15% {
    -webkit-transform: matrix3d(
      0.991,
      0,
      0,
      0,
      0,
      0.991,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
    transform: matrix3d(0.991, 0, 0, 0, 0, 0.991, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  63.26% {
    -webkit-transform: matrix3d(
      1.007,
      0,
      0,
      0,
      0,
      1.007,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
    transform: matrix3d(1.007, 0, 0, 0, 0, 1.007, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  85.49% {
    -webkit-transform: matrix3d(
      0.999,
      0,
      0,
      0,
      0,
      0.999,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
    transform: matrix3d(0.999, 0, 0, 0, 0, 0.999, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  100% {
    -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
}
@-webkit-keyframes ball {
  from {
    -webkit-transform: translateY(0) scaleY(0.8);
    transform: translateY(0) scaleY(0.8);
  }
  to {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
}
@keyframes ball {
  from {
    -webkit-transform: translateY(0) scaleY(0.8);
    transform: translateY(0) scaleY(0.8);
  }
  to {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
}

.App {
  display: grid;
  place-items: center;
}
h1 {
  margin: 0;
}
.board {
  position: relative;
  padding: 0;
}
.tile {
  position: absolute;
  list-style: none;
  background: #ec6f66;
  display: grid;
  place-items: center;
  font-size: 20px;
}
button {
  display: block;
}

.glassLogin {
    color: white;
    background-color: rgba(150, 125, 125, 0.5);
    border-radius: 20px;
}

.update {
    width: 100%;
    padding: 10px;
    border-radius: 20px;
    margin-bottom: 20px;
}
  .app {
    /* width: 100%; */
    /* margin: 0px; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .app-header {
    display: flex;
    justify-content: space-between;
    margin-top: 0.5vh;
    height: auto;
  }
  
  .wheel-history {
    display: flex;
    width: 63vw;
    background-color: rgba(167, 121, 51, 0.6);
    border-radius: 14pt;
    padding-left: 0.5vw;
    margin-left: .3vw;
    font-family: 'Condiment', cursive;
    font-size: 1.7vw;
    align-items: center;
    font-weight: bold;
    color: rgb(8, 8, 8);
  }
  
  .history-num {
    margin: 0px 5px;
    color: rgb(238, 238, 238);
  }
  
  .green-previous-number {
    background-color: rgba(89, 161, 34, 0.9);
    width: 2.45vw;
    height: 2.45vw;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Libre Baskerville', serif;
    border-radius: 10pt;
    padding: .2vh;
    font-size: 1.6vw;
    margin-bottom: 1.2vh;
    margin-left: .1vw;
  }
  
  .red-previous-number {
    background-color: rgba(254, 0, 0, 0.9);
    width: 2.45vw;
    height: 2.45vw;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Libre Baskerville', serif;
    border-radius: 10pt;
    padding: .2vh;
    font-size: 1.6vw;
    margin-bottom: 1.2vh;
    margin-left: .1vw;
  }
  
  .black-previous-number {
    background-color: rgba(22, 22, 22, 0.9);
    width: 2.45vw;
    height: 2.45vw;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Libre Baskerville', serif;
    border-radius: 10pt;
    padding: .2vh;
    font-size: 1.6vw;
    margin-bottom: 1.2vh;
    margin-left: .1vw;
  }
  
  .header-h3 {
    margin: .4vw;
  }
  
  .header-div {
    display: flex;
    align-items: flex-start;
    width: 13vw;
    /* height: 10vh; */
    height: 100%;
    background: linear-gradient(0.25turn, rgb(167,120,51), rgba(167, 121, 51, 0.6));
    text-shadow: rgb(167,120,51) 2px 2px 1px;
    border-radius: 14pt;
    padding-left: 0.5vw;
    margin-left: .3vw;
    font-family: 'Condiment', cursive;
    font-size: 2.5vw;
    color: rgb(12, 12, 12);
  }
  
  .right-header {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
    background: linear-gradient(0.75turn, rgb(167,120,51), rgba(167, 121, 51, 0.6));
    margin-right: .3vw;
    width: 21vw;
  }
  
  .wood-railing {
    width: 98.4vw;
    height: 58vh;
    /* padding-top: 5vh; */
    background-image: url(https://data.1freewallpapers.com/download/polished-wood-texture.jpg);
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 5vh;
    border: rgb(78, 9, 1) 4px solid; 
    border-radius: 14pt;
    /* justify-self: center; */
    /* align-self: center; */
  }
  
  .chip-count-text {
    color: rgb(238, 238, 238);
    text-shadow: rgb(167,120,51) 2.5px 1px 3px;
    font-size: 2.5vw;
    /* font-family: 'Libre Baskerville', serif; */
    margin-bottom: .9vh;
  }
  
  .previous-numbers-div {
    display: flex;
  }
  
  
  .chip-count-pending-bet {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-left: .2vw;
  }
  
  .pending-bet-span {
    font-size: 1.2vw;
    color: rgb(238, 238, 238);
    align-self: flex-end;
    margin-right: 3vw;
    position: absolute;
    right: 2vw;
    bottom: 0vmin;
  }
  
  .empty-pending-bet-span {
    font-size: 1vw;
    color: rgba(240, 248, 255, 0);
    /* position: absolute;
    right: 8vw; */
  }
.layout-div {
    height: 50vh;
    max-width: 95%;
    font-size: 3.2vmin;
    /* width: 1200px; */
    margin-left: 2vw;
    margin-right: 2vw;
    font-family: 'Libre Baskerville', serif;
    font-weight: 900;
    background-image: url(/static/media/blue-felt.8d211669.jpg);
    background-size: contain;
    background-position: center;
    border-radius: 20px;
    padding: 2vh 1.115vw;
    /* padding-left: .5vw; */
    /* padding-right: 1vw; */
    /* padding-right: 20px; */
    cursor: default;
    color: rgb(235, 235, 235);
    /* width: 100%; */
    /* margin-bottom: 10vh; */
}

/* come back to here */
.zeroes {
    display: grid;
    grid-template-columns: 4.2vw;
    grid-template-rows: 13.74vh 0.51vh 13.74vh;
    border-left: .8vw rgb(22, 22, 22) solid;
    /* border-top: 3px rgb(22, 22, 22) solid; */
    box-shadow: inset 0vh -1.15vh rgb(22, 22, 22);
    border-radius: 0px 0px 0px 10px;
}

.inside-and-columns {
    display: grid;
    grid-template-columns: 0.51vw 4.2vw 0.51vw 4.2vw 0.51vw 4.2vw 0.51vw 4.2vw 0.51vw 4.2vw 0.51vw 4.2vw 0.51vw 4.2vw 0.51vw 4.2vw 0.51vw 4.2vw 0.51vw 4.2vw 0.51vw 4.2vw 0.51vw 4.2vw 4.2vw;
    grid-template-rows: 8.8vh 0.51vh 8.8vh 0.51vh 8.8vh 0.51vh ;
    /* border: 2px rgb(22, 22, 22) solid; */
}

.dozens {
    display: grid;
    grid-template-columns: 6.77vw 27vw 27.05vw 27.7vw;
    grid-template-rows: 9.5vh;
    color: rgb(22, 22, 22);
}

.even-money-bets {
    font-size: 4.5vmin;
    display: grid;
    grid-template-columns: 6.76vw 13.5vw 13.52vw 13.52vw 13.52vw 13.86vw 13.86vw;
    grid-template-rows: 9vh;
    color: rgb(22, 22, 22);
}

#one-chip-cursor {
    cursor: url(/static/media/1-chip-cursor.37bbd0f6.png), auto;
}

#five-chip-cursor {
    cursor: url(/static/media/5-chip-cursor.a221d8af.png), auto;
}

#twenty-five-chip-cursor {
    cursor: url(/static/media/25-chip-cursor.584973f6.png), auto;
}

#one-hundred-chip-cursor {
    cursor: url(/static/media/100-chip-cursor.3ec7deba.png), auto;
}

#five-hundred-chip-cursor {
    cursor: url(/static/media/500-chip-cursor.3eface1f.png), auto;
}

#one-thousand-chip-cursor {
    cursor: url(/static/media/1000-chip-cursor.af42c8fd.png), auto;
}

#ten-thousand-chip-cursor {
    cursor: url(/static/media/10k-chip-cursor.d2359734.png), auto;
}

#fifty-thousand-chip-cursor {
    cursor: url(/static/media/50k-chip-cursor.d3fec7d7.png), auto;
}

#hundred-thousand-chip-cursor {
    cursor: url(/static/media/100k-chip-cursor.a0fc1795.png), auto;
}

#five-hundred-thousand-chip-cursor {
    cursor: url(/static/media/500k-chip-cursor.72d38db4.png), auto;
}

#one-million-chip-cursor {
    cursor: url(/static/media/1m-chip-cursor.2e340d71.png), auto;
}

.bet-box {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    /* border: 1px rgb(22, 22, 22) solid; */
    /* cursor: url(../images/1-chip-cursor.png), auto; */
}

.bet-box > .green {
    background-color: rgb(90, 161, 34);
    margin: 2px;
}

.bet-box > .red {
    background-color: rgb(254,0,0);
    margin: 2px;
}

.bet-box > .black {
    background-color: rgb(22, 22, 22);
    margin: 2px;
}

.bet-box:hover {
    background-color: gold;
    /* background: linear-gradient(0.25turn, rgba(255, 217, 0, 0.85), gold, rgba(255, 217, 0, 0.85)); */

}

.zeroes-inside-columns {
    display: flex;
    width: 100%;
    border-top: .65vw rgb(22, 22, 22) solid;
    /* border-right: .7vw rgb(22, 22, 22) solid; */
    /* border-bottom: .7vw rgb(22, 22, 22) solid; */
    /* flex-direction: row; */
    /* justify-content: center; */
    border-radius: 10px 10px 10px 10px;
    /* box-shadow: inset 0vh 1vh rgb(22, 22, 22); */
}

.empty-column {
    border-radius: 0px 0px 10px 0px;
}

#straight-up-number {
    font-size: 5vmin;
    /* padding: 10px 10px; */
}

.number {
    /* border: 1px rgb(22, 22, 22) solid; */
    border-radius: 60pt;
    width: 100%;
    height: 75%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

#empty-remove-border {
    /* border-left: 1.7vw transparent solid; */
}

.fill {
    background-color: rgb(22, 22, 22);
}

.diamond {
    height: 7.8vmin;
}

.ribbon-div {
    height: 10vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    
}

.dozen {
    border-bottom: .75vw rgb(22, 22, 22) solid;
    border-left: .75vw rgb(22, 22, 22) solid;
}

.right-dozen {
    border-right: .75vw rgb(22, 22, 22) solid;
    border-bottom: .75vw rgb(22, 22, 22) solid;
    border-left: .75vw rgb(22, 22, 22) solid;
}

.ribbon-img {
    position: absolute;
    width: 32vmin;
    margin-top: 0.5vh;
}

.dozen-text {
    position: absolute;
    /* padding-bottom: 44px; */
    padding-bottom: 5vh;
    /* margin: 0; */
}

.column {
    color: rgb(22, 22, 22);
    border-left: .75vw rgb(22, 22, 22) solid;
    border-right: .75vw rgb(22, 22, 22) solid;
    font-size: 2.4vmin;
}

.split, .basket, .corner, .street, .double-street {
    background-color: rgb(22, 22, 22);
}

.even-money {
    border-bottom: .75vw rgb(22, 22, 22) solid;
    border-left: .75vw rgb(22, 22, 22) solid;
}

.left-even-money {
    border-bottom: .75vw rgb(22, 22, 22) solid;
    border-left: .75vw rgb(22, 22, 22) solid;
    border-radius: 0px 0px 0px 10px;

}

.right-even-money {
    border-right: .75vw rgb(22, 22, 22) solid;
    border-bottom: .75vw rgb(22, 22, 22) solid;
    border-left: .75vw rgb(22, 22, 22) solid;
    border-radius: 0px 0px 10px 0px;
}

.blank-chip {
    height: 7vh;
    z-index: 50;
    /* position: absolute; */
}   

.chip-and-bet {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
}

.bet-text {
    position: absolute;
    font-size: 1vmin;
    color: rgb(22, 22, 22);
    z-index: 100;
    padding-bottom: 1.8vmin;
    text-shadow: 0px 0px 1px white;
}

.dozen-chip {
    
}

.winning-num {
    -webkit-animation-name: flashingNumber;
            animation-name: flashingNumber;
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
}

@-webkit-keyframes flashingNumber {
    0% {background-color: gold;}
    50% {background-color: rgba(255, 217, 0, 0);}
    100% {background-color: gold;}
}

@keyframes flashingNumber {
    0% {background-color: gold;}
    50% {background-color: rgba(255, 217, 0, 0);}
    100% {background-color: gold;}
}

.betting-options-div {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 4px;
    width: 100%;
}

.chip-options-div {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    align-items: flex-end;
    width: 60vw;
    height: 25vh;
}

.chip-img {
    height: 10vh;
    cursor: pointer;
}

.chip-img:hover {
    border: 4px gold solid;
    border-radius: 60px;
}

.active-chip {
    border: gold 10px solid;
    cursor: pointer;
    height: 10vh;
    border-radius: 60pt;
}

.spin-circle {
    height: 17vh;
    cursor: pointer;
    /* margin-top: 10px;
    margin-bottom: 10px; */
    /* padding-top: 10px;
    padding-bottom: 10px; */
}

.spin-circle:hover {
    border: 8px gold solid;
    border-radius: 60pt;
}

.spin-circle:active {
    border: 4px gold solid;
    border-radius: 60pt;   
}

.spin-circle-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 19.5vh;
    width: 19.5vh;
}

.spin-text {
    color: rgb(238, 238, 238);
    font-family: 'Condiment', cursive;
    font-size: 4vmin;
    position: absolute;
    margin: 0px;
    text-shadow: 2px 2px 2px rgb(167,120,51);
    cursor: pointer;
}

.img-div {
    height: 13vmin;
    width: 13vmin;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.spin-img-div {
    height: 100%;
    width: 20vmin;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: flex-start;
}

.buttons-div {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    height: 4.5vmin;
}

.filler-div {
    width: 100%;
    height: 4vmin;
}

.wheel-div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.roulette-wheel {
    height: 25vh;
}
.roulette-wheel-spinning {
    height: 25vh;
    -webkit-animation-name: spin;
            animation-name: spin;
    -webkit-animation-duration: 500ms;
            animation-duration: 500ms;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
}

.wheel-number-div {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 14.5vh;
    width: 14.5vh;
    border-radius: 100pt;
    cursor: default;
}

.winning-number-h2 {
    margin-top: 0px;
    margin-bottom: 0px;
    color: rgb(240, 240, 240);
    font-size: 9vmin;
    border-radius: 60pt;
    /* padding: 1vw; */
    font-family: 'Libre Baskerville', serif;
    font-weight: 900;
    /* width: 5%;
    height: 10%; */
    text-align: center;
}

.green-wheel-number {
    background-color: rgba(89, 161, 34, 0.9);
}

.red-wheel-number {
    background-color: rgba(254, 0, 0, 0.9);

}

.black-wheel-number {
    background-color: rgba(22, 22, 22, 0.9);
}

.winnings-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgb(167,120,51, 0.85);
    z-index: 101;
    border-radius: 6pt;
    padding: 3vh;
    padding-top:  0vh;
    padding-bottom: 0vh;
    width: 100%;
    height: 18vmin;
    font-family: 'Condiment', cursive;
}
  
.winnings-div > h3 {
    font-size: 5.5vmin;
    color: rgb(8, 8, 8);
    text-shadow: rgb(167,120,51) 2px 2px 1px;
    text-align: center;
    margin-bottom: 0vh;
    margin-top: 0vh;
}

.restart-button, 
.collect-winnings-button, 
.reset-button, 
.undo-bet-button {
    padding: 1vh;
    margin-top: 1vh;
    border-radius: 4pt;
    font-family: 'Libre Baskerville', serif;
    font-size: 0.7vw;
}

.restart-button:focus, 
.collect-winnings-button:focus,
.reset-button:focus, 
.undo-bet-button:focus {
    outline: none;
}

.restart-button:active, 
.collect-winnings-button:active,
.reset-button:active, 
.undo-bet-button:active {
    background-color: rgb(170, 170, 170);
    border: 1px solid rgb(167,120,51);
}

.reset-button-div {
    display: flex;
    justify-content: center;
    position: absolute;
    width: auto;
    min-width: 35vw;
    max-width: 65vw;
}  

.winnings-div-num {
    color: rgb(238, 238, 238);
    margin-left: 1vw;
}

@-webkit-keyframes spin {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

@keyframes spin {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}


.gridLB {
  display: grid;
  grid-template-columns: 1fr 4fr 1fr 4fr 1fr 4fr 1fr 4fr 1fr 4fr 1fr 4fr 1fr 4fr 1fr 4fr 1fr 4fr 1fr 4fr 1fr 4fr 1fr 4fr 1fr;
  grid-template-rows:
    0.5em minmax(20px, 60px) 0.5em minmax(20px, 60px) 0.5em minmax(20px, 60px)
    0.5em;
  grid-gap: 0px 0px;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas:
    ". . . . . . . . . . . . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . . . . . . . . . . . .";
}

.hover:hover {
  background-color: gold;
}

.hover {
  background-color: black;
}

.hover-number {
  background-color: transparent;
}

.hover-number:hover {
  background-color: gold;
}

.sidebarchat {
  display: flex;
  padding: 5%;
  cursor: pointer;
  border-bottom: 1px solid #626262;
}

.sidebarchat:hover {
  background-color: #ebebeb;
}

.sidebarChatInfo > h2 {
  font-size: 16px;
  margin-bottom: 8px;
}

.sidebarChatInfo {
  margin-left: 0.5rem;
}

.sidebar {
    border: 1px solid black;
}
.sidebarSearch {
    display: flex;
    align-items: center;
    background-color: khaki;
    height: 40px;
}

.sidebarSearchContainer {
    display: flex;
    align-items: center;
    background-color: white;
    width: 100%;
    height: 35px;
}

.sidebarSearchContainer > input {
    border: none;
    margin-left: 10px
}

.sidebarChats {
    flex: 1 1;
    background-color: white;
}
.chatHeader {
    padding: 20px;
    display: flex;
    align-items: center;
    background-color: rgba(0,0,0,0.2);
}

.chatHeaderInfo {
    flex: 1 1;
    padding-left: 20px;
}

.chatHeaderInfo > h3 {
    margin-bottom: 3px;
    font-weight: 500;
}

.chatHeaderInfo > p {
    color: khaki;
}

.chatBody {
    flex: 1 1;
    padding: 30px;
}
.card.user-card {
    border-top: none;
    box-shadow: 0 0 1px 2px rgba(0,0,0,0.05), 0 -2px 1px -2px rgba(0,0,0,0.04), 0 0 0 -1px rgba(0,0,0,0.05);
    transition: all 150ms linear;
}

.card {
    border-radius: 5px;
    box-shadow: 0 1px 2.94px 0.06px rgba(4,26,55,0.16);
    border: none;
    margin-bottom: 30px;
    transition: all 0.3s ease-in-out;
}

.card .card-header {
    background-color: transparent;
    border-bottom: none;
    padding: 25px;
}

.card .card-header h5 {
    margin-bottom: 0;
    color: #222;
    font-size: 14px;
    font-weight: 600;
    display: inline-block;
    margin-right: 10px;
    line-height: 1.4;
}

.card .card-header+.card-block, .card .card-header+.card-block-big {
    padding-top: 0;
}

.user-card .card-block {
    text-align: center;
}

.card .card-block {
    padding: 25px;
}

.user-card .card-block .user-image {
    position: relative;
    margin: 0 auto;
    display: inline-block;
    padding: 5px;
    width: 110px;
    height: 110px;
}

.user-card .card-block .user-image img {
    z-index: 20;
    position: absolute;
    top: 5px;
    left: 5px;
        width: 100px;
    height: 100px;
}

.img-radius {
    border-radius: 50%;
    height: 150px;
    width: 150px;
}

.f-w-600 {
    font-weight: 600;
}

.m-b-10 {
    margin-bottom: 10px;
}

.m-t-25 {
    margin-top: 25px;
}

.m-t-15 {
    margin-top: 15px;
}

.card .card-block p {
    line-height: 1.4;
}

.text-muted {
    color: #919aa3!important;
}

.user-card .card-block .activity-leval li.active {
    background-color: #2ed8b6;
}

.user-card .card-block .activity-leval li {
    display: inline-block;
    width: 15%;
    height: 4px;
    margin: 0 3px;
    background-color: #ccc;
}

.user-card .card-block .counter-block {
    color: #fff;
}

.bg-c-blue {
    background: linear-gradient(45deg,#9fc3ec,#73b4ff);
}

.bg-c-green {
    background: linear-gradient(45deg,#2ed8b6,#59e0c5);
}

.bg-c-yellow {
    background: linear-gradient(45deg,#FFB64D,#ffcb80);
}

.bg-c-pink {
    background: linear-gradient(45deg,#FF5370,#ff869a);
}

.m-t-10 {
    margin-top: 10px;
}

.p-20 {
    padding: 20px;
}

.user-card .card-block .user-social-link i {
    font-size: 30px;
}

.text-facebook {
    color: #3B5997;
}

.text-twitter {
    color: #42C0FB;
}

.text-dribbble {
    color: #EC4A89;
}

.user-card .card-block .user-image:before {
    bottom: 0;
    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 50px;
}

.user-card .card-block .user-image:after, .user-card .card-block .user-image:before {
    content: "";
    width: 100%;
    height: 48%;
    /*border: 2px solid #4099ff;*/
    position: absolute;
    left: 0;
    z-index: 10;
}

.user-card .card-block .user-image:after {
    top: 0;
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
}

.user-card .card-block .user-image:after, .user-card .card-block .user-image:before {
    content: "";
    width: 100%;
    height: 48%;
    /*border: 2px solid #4099ff;*/
    position: absolute;
    left: 0;
    z-index: 10;
}
.main {
    background: linear-gradient(to right top, #b89046, #ffd9725d)
}

.glass {
    margin: 2rem;
    width: 90%;
    background: linear-gradient(
        to right bottom, 
        rgba(255,255,255,0.7),
        rgba(255,255,255,0.3)
    );
    border-radius: 2rem;
}

.dash {
    background: linear-gradient(
        to right bottom, 
        rgba(255,255,255,0.7),
        rgba(255,255,255,0.3)
    );
    border-radius: 2rem;
}

.status {
    margin: 1rem;
}
.craft-title {
  font-size: 16px;
  font-weight: 700;
  color: #333;
  margin: 0 0 30px
}

.craft {
  height: 20px;
  background: #333;
  border-radius: 0;
  box-shadow: none;
  margin-bottom: 30px;
  overflow: visible
}

.craft .craft-bar {
  height: 20px;
  position: relative;
  -webkit-animation: animate-positive 2s;
  animation: animate-positive 2s
}

.craft .craft-value {
  display: block;
  font-size: 18px;
  font-weight: 500;
  color: black;
  position: absolute;
  top: -30px;
  right: -25px
}

.craft .craft-bar:after {
  content: "";
  display: inline-block;
  width: 10px;
  background: #fff;
  position: absolute;
  top: -10px;
  bottom: -10px;
  right: -5px;
  z-index: 1;
  -webkit-transform: rotate(35deg);
          transform: rotate(35deg)
}
/* Switch info */
.switch-info label input[type=checkbox]:checked+.lever {
  background-color: #33b5e5;
}

.switch-info label input[type=checkbox]:checked+.lever:after {
  background-color: #0099CC;
}

/* Switch danger */
.switch-danger custom-control-label input[type=checkbox]:checked+.lever {
  background-color: #ff4444;
}

.switch-danger custom-control-label input[type=checkbox]:checked+.lever:after {
  background-color: #CC0000;
}

.gesin .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #CC0000;
}
.gesin .custom-control-label::before {
  background-color: lawngreen;
}

.gesin .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: black;
}

.gesin .custom-switch .custom-control-label::after {
  background-color: white;
}

/* Switch warning */
.switch-warning label input[type=checkbox]:checked+.lever {
  background-color: #ffbb33;
}

.switch-warning label input[type=checkbox]:checked+.lever:after {
  background-color: #FF8800;
}

/* Switch success */
.switch-success label input[type=checkbox]:checked+.lever {
  background-color: #00C851;
}

.switch-success label input[type=checkbox]:checked+.lever:after {
  background-color: #007E33;
}
.imgT {
  width: 100%;
  height: auto;
}
@media screen and (min-width: 480px) {
  .imgT {
    width: 50%;
    height: auto;
  }
}

.tempelBox {
  top: 1%;
  left: 1%;
  width: 95%;
}
@media screen and (min-width: 480px) {
  .tempelBox {
    top: 1%;
    left: 26%;
    width: 46%;
  }
}

.picColor {
  width: 40%;
  height: 40%;
  margin-right: 0.25rem;
}
@media screen and (min-width: 480px) {
  .picColor {
    width: 19%;
    height: 19%;
    margin-right: 1rem;
  }
}

.picGrey {
  width: 35%;
  height: 35%;
  margin-right: 0.25rem;
}
@media screen and (min-width: 480px) {
  .picGrey {
    width: 17%;
    height: 17%;
    margin-right: 1rem;
  }
}

