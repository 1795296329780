.App {
  display: grid;
  place-items: center;
}
h1 {
  margin: 0;
}
.board {
  position: relative;
  padding: 0;
}
.tile {
  position: absolute;
  list-style: none;
  background: #ec6f66;
  display: grid;
  place-items: center;
  font-size: 20px;
}
button {
  display: block;
}
