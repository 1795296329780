.gridLB {
  display: grid;
  grid-template-columns: 1fr 4fr 1fr 4fr 1fr 4fr 1fr 4fr 1fr 4fr 1fr 4fr 1fr 4fr 1fr 4fr 1fr 4fr 1fr 4fr 1fr 4fr 1fr 4fr 1fr;
  grid-template-rows:
    0.5em minmax(20px, 60px) 0.5em minmax(20px, 60px) 0.5em minmax(20px, 60px)
    0.5em;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas:
    ". . . . . . . . . . . . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . . . . . . . . . . . .";
}

.hover:hover {
  background-color: gold;
}

.hover {
  background-color: black;
}

.hover-number {
  background-color: transparent;
}

.hover-number:hover {
  background-color: gold;
}
